import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'

export default function Layout() {
  return (
    
    <div className="min-h-screen bg-gray-100">
        <Navbar />
        
        <main>
            <Outlet />
        </main>

        <footer className="bg-gray-200 p-4 mt-8 text-center">
            <p>Contact us at <a href="mailto:kaipismike1@email.com" className="text-blue-500">kaipismike1@email.com</a> for more information.</p>
        </footer>
        
    </div>

  )
}
