import React from 'react'
import VideoPlayer from '../../components/VideoPlayer';


export default function VideoPage() {
  return (
    
        <section className="py-8">
            <h2>How to use the app</h2>
            <VideoPlayer src={"assets/videos/explanationVideo.mp4"}/>
          
        </section>
    
  );
}
