import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Layout from './pages/Layout/Layout';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import DownloadPage from './pages/DownloadPage/DownloadPage';
import VideoPage from './pages/VideoPage/VideoPage';
import PrivacyPolicyHabitTracker from './pages/PrivacyPolicy/PrivacyPolicyHabitTracker';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Layout />} >
        <Route index element={<Home />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="privacy-policy-hubit-tracker" element={<PrivacyPolicyHabitTracker />} />
        <Route path="downloads" element={<DownloadPage />} />
        <Route path="videos" element={<VideoPage />} />
      </Route>      
    </Routes>
  </BrowserRouter>
  );
}

export default App;
