import React from 'react';

function VideoPlayer({src}) {
  return (
    <div>
      <video controls width="640" height="360">
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;

