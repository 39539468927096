import React, { useState } from "react";
import { Link } from "react-router-dom";

// home page
export default function Home() {
  return (
    <main className="container mx-auto mt-8 px-4">
      <section className="py-8">
        <h2 className="text-2xl font-semibold">What our produt is?</h2>
        <p className="text-gray-700">
          An application that keeps track of your team members attendance.
          Easily access the data on any device.
        </p>
      </section>
      <section className="py-8">
        <h2 className="text-2xl font-semibold">Setup Process</h2>
        <p className="text-gray-700">
          Download the app from{" "}
          <Link to={"downloads"} className="underline text-blue-500">
            here
          </Link>
          . U can eather download the local app or the cloud app.
        </p>
        <br />
        <div className="mx-4 p-2 bg-red-100 text-gray-600">
          Note: because the installation is not from the google app store so the
          phone thinks is not safe, the installation process needs to have the
          bellow steps
        </div>

        <ProgressUI />

        <br />
        <p className="text-gray-700">
          Only for the cloud app in the start screen, u need to create a
          <strong> name</strong> of your team and a <strong>password</strong>.
        </p>
      </section>
    </main>
  );
}

function ProgressUI(props) {
  const steps = [
    {
      title: "Step 1",
      description: <div>"Go to settings"</div>,
    },
    {
      title: "Step 2",
      description: "Go to security",
    },
    {
      title: "Step 3",
      description: "Enable unknown sources",
    },
    {
      title: "Step 4",
      description: "Click on the downloaded file",
    },
    {
      title: "Step 5",
      description: "Click on install",
    },
  ];

  const [currentStep, setCurrentStep] = useState(2);

  return (
    <div>
      <h2 className="text-center text-2xl font-semibold text-blue-500 my-4">
        Installation Steps
      </h2>
      <div className="mx-auto w-[400px] flex items-center justify-center">
        {
          // steps
          steps.map((step, index) => {
            return (
              <>
                <div
                  key={index}
                  className="relative w-10 h-10 flex-shrink-0 border-2 bg-white rounded-full cursor-pointer hover:bg-blue-500 hover:border-blue-500 hover:bg-opacity-10 transition-all"
                  onClick={() => setCurrentStep(index)}
                >
                  <div
                    className={`absolute inset-0 ${
                      index <= currentStep
                        ? "bg-blue-500 hover:bg-opacity-80"
                        : "border-[#7b7b7bb6] border-2"
                    } rounded-full`}
                  ></div>
                  <div className="absolute top-10 w-20 left-5 -translate-x-1/2 text-center text-sm font-semibold">
                    {step.title}
                  </div>
                </div>
                {index !== steps.length - 1 && (
                  <div className="w-full h-[1px] bg-[#7b7b7bb6]"></div>
                )}
              </>
            );
          })
        }
      </div>
      <div className="text-center text-gray-700 mt-8">
        {steps[currentStep].description}

        <div className="flex items-center justify-center mt-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
            onClick={() => setCurrentStep(currentStep - 1)}
            disabled={currentStep === 0}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-md ml-4"
            onClick={() => setCurrentStep(currentStep + 1)}
            disabled={currentStep === steps.length - 1}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
