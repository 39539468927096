import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbar() {
  return (
    <header className="bg-blue-500 p-4 flex justify-between">
        <div>
          <Link to="/">
            <h1 className="text-3xl text-white font-semibold">TeamTrackPro</h1>
            <p className="text-sm text-gray-200">Your Ultimate Team Management Solution</p>
          </Link>
        </div>
        <div className="mt-4 mr-4">
        <Link to="/videos" className='text-sm text-white mr-4'>Videos</Link>
        <Link to="/downloads" className='text-sm text-white mr-4'>Downloads</Link>
          <Link to="/privacy-policy" className='text-sm text-white'>Privacy Policy</Link>
        </div>
    </header>
  )
}
