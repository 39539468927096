import React from "react";
import { Link } from "react-router-dom";

export default function DownloadPage() {
  const fileName = "TeamTrackPro-cloud.apk";

  // Create the S3 URL for the file
  const s3Url = `https://mikestorage.s3.amazonaws.com/${fileName}`;

  return (
    <section className="py-8">
      <p>
        The program has two versions, the <strong>local</strong> and the{" "}
        <strong>cloud </strong>
        app.
        <br />
        The local app is build for using it only in one device offline, instead
        the cloud app is build in order to have access to the same data from
        multiple devices.
      </p>
      <br />
      <p>
        Download the local App
        <a
          className="text-blue-500 underline"
          href="./app/TeamTrackPro.apk"
          download="TeamTrackPro.apk"
        >
          here
        </a>
      </p>
      <p>
        Download the cloud App
        <a className="text-blue-500 underline" href={s3Url} download={fileName}>
          here
        </a>
      </p>
      <br />
      <p>
        Download the youtube to mp3 downloader app{" "}
        <a
          className="text-blue-500 underline"
          href="./app/YoutubeToMp3Downloader.apk"
          download="YoutubeToMp3Downloader.apk"
        >
          here
        </a>
      </p>
      <br />
      
      <div className="flex items-center gap-4">

      <p>
        Download my Habbit tracker app{" "}
        <a
          className="text-blue-500 underline"
          href="./app/habit-tracker.apk"
          download="HabitTracker.apk"
          >
          here
        </a>.
      </p>
        <img src="assets/habit-tracker-logo.png" alt="" className="w-[50px] aspect-square border-2 border-black p-1 rounded-lg"/>
          </div>
    </section>
  );
}
